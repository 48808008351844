import React, { useContext, useEffect } from "react";
import LandingPageContent from "../components/LandingPageContent";
import PageContainer from "./PageContainer";
import UserContext from "../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { isLoggedIn } from "../api/signup_or_login";

export default function HomePage() {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn(user)) {
      history.push("/feed/first");
    }
  }, [user]);

  return (
    <PageContainer>
      <LandingPageContent />
    </PageContainer>
  );
}
