// Env variables
export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

// Layout variables
export const slimWidth = 700;
export const headerHeight = 64;
export const footerHeight = 100;
export const topMargin = 24;
export const navbarDrawerWidth = 200;
export const swapNavbarWidth = 1100;

// Other variables
export const MIN_CHARS_PER_EPISODE = 20;
export const MAX_CHARS_PER_EPISODE = 10000;
