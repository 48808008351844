import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/core";
import LoginForm from "../components/LoginForm";
import SignupForm from "../components/SignupForm";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import PageContainer from "./PageContainer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default function LoginPage() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const signup = urlParams.get("signup") === "true";
  const history = useHistory();

  const [formMode, setFormMode] = React.useState(signup ? "signup" : "login");

  const handleFormModeChange = (event, newMode) => {
    if (newMode !== null) {
      setFormMode(newMode);
    }
  };

  useEffect(() => {
    // If the user is logged in, redirect to the account page
    if (isLoggedIn(user)) {
      history.push("/account");
    }
  }, [user]);

  return (
    <PageContainer>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <ToggleButtonGroup
            value={formMode}
            exclusive
            onChange={handleFormModeChange}
          >
            <ToggleButton value="signup">Sign Up</ToggleButton>
            <ToggleButton value="login">Log in</ToggleButton>
          </ToggleButtonGroup>
          {formMode === "signup" ? <SignupForm /> : <LoginForm />}
        </CardContent>
      </Card>
    </PageContainer>
  );
}
