import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent } from "@material-ui/core";
import LoggedInPageContainer from "./LoggedInPageContainer";
import AccountDetails from "../components/AccountDetails";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles({
  card: {
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default function AccountPage() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <LoggedInPageContainer>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isLoggedIn(user) && <AccountDetails />}
        </CardContent>
      </Card>
    </LoggedInPageContainer>
  );
}
