import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Box,
  Tooltip,
  IconButton,
  Button,
  Collapse,
  Grid,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpdateFeedDetailsForm from "./UpdateFeedDetailsForm";
import { BASE_URL } from "../constants";
import FeedContext from "../contexts/FeedContext";
import grey from "@material-ui/core/colors/grey";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { slimWidth } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  upperSection: {
    marginBottom: "2rem",
  },
  previewImageSmall: {
    width: "100%",
    maxWidth: "180px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    display: "block",
    margin: "auto",
  },
  previewImageLarge: {
    width: "100%",
    maxWidth: "150px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
  },
  textContent: {
    display: "flex",
    flexDirection: "column",
  },
  feedName: {
    margin: 0,
  },
  feedLinkText: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  copyIcon: {
    marginLeft: "8px",
    fontSize: "1rem",
    verticalAlign: "middle",
  },
  editButton: {
    marginTop: "8px",
    marginBottom: "8px",
    backgroundColor: "transparent",
    color: theme.palette ? theme.palette.text.primary : "inherit",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      boxShadow: "none",
    },
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: theme.transitions
      ? theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        })
      : "transform 0.3s ease-in-out",
  },
  expandIconOpen: {
    transform: "rotate(180deg)",
  },
  buttonText: {
    flexGrow: 1,
    textAlign: "left",
  },
  feedLinkContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    padding: "0.5rem",
    backgroundColor: grey[100],
    borderRadius: "4px",
    maxWidth: "100%", // Ensure the container doesn't exceed its parent's width
  },
  feedLinkLabel: {
    fontWeight: "bold",
    marginRight: "0.5rem",
    flexShrink: 0,
  },
  feedLinkWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0, // Allow the wrapper to shrink below its content size
    overflow: "hidden", // Hide overflow
    marginRight: "0.5rem",
  },
  feedLinkScroll: {
    overflowX: "auto", // Enable horizontal scrolling
    whiteSpace: "nowrap", // Keep content on a single line
  },
  feedLink: {
    color: "#1976d2",
    textDecoration: "none",
    cursor: "pointer",
  },
  copyButton: {
    flexShrink: 0,
  },
}));

export default function FeedDetails() {
  const classes = useStyles();

  const { width } = useWindowDimensions();
  const { activeFeed, updateFeed } = useContext(FeedContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const handleCopyLink = () => {
    const feedUrl = `${BASE_URL}/rss/${activeFeed.uuid}/`;
    navigator.clipboard.writeText(feedUrl).then(() => {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Auto hide tooltip after 2 seconds
    });
  };

  const toggleUpdateForm = () => {
    setShowUpdateForm(!showUpdateForm);
  };

  return (
    <div className={classes.root}>
      {activeFeed !== null && (
        <>
          <Grid container spacing={3} className={classes.upperSection}>
            <Grid item xs={12} sm={4} md={3}>
              <img
                src={activeFeed.image}
                alt="Preview"
                className={
                  width > slimWidth
                    ? classes.previewImageLarge
                    : classes.previewImageSmall
                }
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className={classes.textContent}>
                <Typography variant="h2" className={classes.feedName}>
                  {activeFeed.name}
                </Typography>
                <Box className={classes.feedLinkContainer}>
                  <Box className={classes.feedLinkWrapper}>
                    <Box className={classes.feedLinkScroll}>
                      <Typography
                        className={classes.feedLink}
                        component="span"
                        onClick={handleCopyLink}
                      >
                        {`${BASE_URL}/rss/${activeFeed.uuid}/`}
                      </Typography>
                    </Box>
                  </Box>
                  <Tooltip
                    title="Link copied to clipboard!"
                    open={tooltipOpen}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    arrow
                  >
                    <IconButton
                      onClick={handleCopyLink}
                      className={classes.copyButton}
                      size="small"
                      color="primary"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant="caption">
                  Copy and paste this link into your podcast app to listen.
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Button
            variant="text"
            fullWidth
            className={classes.editButton}
            onClick={toggleUpdateForm}
            endIcon={
              <ExpandMoreIcon
                className={`${classes.expandIcon} ${
                  showUpdateForm ? classes.expandIconOpen : ""
                }`}
              />
            }
          >
            <span className={classes.buttonText}>Edit Feed Details</span>
          </Button>

          <Collapse in={showUpdateForm}>
            <UpdateFeedDetailsForm
              key={activeFeed.uuid}
              onSubmit={updateFeed}
            />
          </Collapse>
        </>
      )}
    </div>
  );
}
