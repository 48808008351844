import React, { useState, useEffect } from "react";
import UserContext from "./UserContext";
import { getUser } from "../api/user";

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const loadUser = async () => {
    try {
      const userData = await getUser();
      setUser(userData);
    } catch (error) {
      console.error("Error loading user:", error);
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, userLoading, loadUser }}>
      {children}
    </UserContext.Provider>
  );
};
