import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import EpisodeList from "../components/EpisodeList";
import FeedDetails from "../components/FeedDetails";
import LoggedInPageContainer from "./LoggedInPageContainer";
import FeedContext from "../contexts/FeedContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  section: {
    marginBottom: "2rem",
  },
});

export default function FeedPage() {
  const classes = useStyles();
  const { uuid } = useParams();
  const { switchFeed, newFeed, activeFeedUuid } = useContext(FeedContext);
  const history = useHistory();

  useEffect(() => {
    if (uuid === "new") {
      newFeed().then((createdFeed) => {
        switchFeed(createdFeed.uuid);
        history.push(`/feed/${createdFeed.uuid}`);
      });
    } else if (uuid === "first") {
      switchFeed("first");
      if (activeFeedUuid) {
        history.push(`/feed/${activeFeedUuid}`);
      }
    } else {
      switchFeed(uuid);
    }
  }, [uuid, activeFeedUuid, history]);

  return (
    <LoggedInPageContainer>
      <div className={classes.section}>
        <FeedDetails key={activeFeedUuid} />
      </div>
      <div className={classes.section}>
        <EpisodeList key={activeFeedUuid} />
      </div>
    </LoggedInPageContainer>
  );
}
