import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { headerHeight, footerHeight, topMargin } from "../constants";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    marginTop: topMargin,
    height: "100%",
    width: "100%",
    minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px - ${topMargin}px)`,
  },
});

export default function LoggedInPageContainer({ children }) {
  const classes = useStyles();
  const history = useHistory();

  const { user, userLoading } = useContext(UserContext);
  const { feedLoading } = useContext(FeedContext);

  useEffect(() => {
    // Redirect to login if the user is not logged in
    if (!userLoading && !feedLoading && !isLoggedIn(user)) {
      history.push("/login");
    }
  }, [user, userLoading, feedLoading, history]);

  if (userLoading || feedLoading || !isLoggedIn(user)) {
    return <div>Loading...</div>;
  }

  return <div className={classes.root}>{children}</div>;
}
