import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography } from "@material-ui/core";
import PricingOptions from "./PricingOptions.js";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function LandingPageContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" gutterBottom>
        Podcasting, without all the talking
      </Typography>
      <Typography variant="h4" gutterBottom>
        Create your own podcast by just typing or pasting a text script, and Pod
        Reader gives you a hosted podcast with a natural sounding AI voice.
      </Typography>

      <PricingOptions />
    </div>
  );
}
